@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Thin.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Light.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Medium.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Black.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Regular.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GraphikArabic";
  src: url("./assets/fonts/GraphikArabic-Bold.woff2") format("woff2"),
    url("./assets/fonts/GraphikArabic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-weight: regular;
  font-size: 22px;
  line-height: 37px;
  overflow-x: hidden;
  color: #000000;
}

@media screen and (max-width: 800px) {
  body {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  body {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 300px) {
  body {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 200px) {
  body {
    font-size: 14px !important;
  }
}

$primary-color: hsl(120, 9%, 35%);
$primary-color-hover: hsl(120, 9%, 50%);

::-webkit-scrollbar {
  width: 0px;
  height: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px $primary-color;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color-hover;
}

/**
  * Removes the default scrollbar of body
  */
@-moz-document url-prefix() {
  /* Disable scrollbar Firefox */
  html {
    scrollbar-width: 10px;
  }
}

body {
  margin: 0;
  scrollbar-width: 10px;
  -ms-overflow-style: none;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

body::-webkit-scrollbar {
  // width: 10px;
  // height: 10px;
  width: 0px;
  height: 0px;
  background: transparent;
  transition: all 0.3s ease-in-out;
}

::selection {
  background-color: $primary-color;
  color: #ffffff;
}

.m-y-20 {
  margin: 20px 0px;
}

.mySwiper .swiper-button-next {
  // background-image: url(./assets/svgs/arrow-circle-left-white.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.mySwiper .swiper-button-next::after {
  display: none !important;
}

.mySwiper .swiper-button-prev {
  // background-image: url(./assets/svgs/arrow-circle-right-white.svg) !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.mySwiper .swiper-button-prev::after {
  display: none !important;
}

@media print {
  .no-print {
    display: none;
  }

  .hidden {
    visibility: hidden;
  }

  .print {
    display: block !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

h1 {
  font-weight: 500;
}

h2 {
  color: #516151;
  font-size: 2rem;
  font-weight: 400;
}
